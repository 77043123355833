.mh-container {
	position: fixed;
	display: flex;
	justify-content: space-between;
	align-items: center;
	top: 0;
	left: 0;
	width: 100%;
	height: 60px;
	padding: 15px 30px 15px 30px;
	background: $transparent-bck;
	z-index: $z-index-5;
}

.mh-logo-img {
	width: 300px;
}

.mh-hamburger-container {
	line-height: 0;
	width: 64px;
}

.mh-hamburger {
	display: inline-block;
	position: relative;
	width: 35px;
	z-index: $z-index-7;
	cursor: pointer;
}

.mh-hamburger--active {
	.mh-hamburger-line:nth-of-type(1) {
		@include rotate(-45deg);
		@include transitionDouble(transform, 0.3s, width, 0.3s);
		width: 77%;
		margin-left: 0;
		transform-origin: top right;
	}

	.mh-hamburger-line:nth-of-type(2) {
		@include translateX(-30px);
		opacity: 0;
		transition: transform 0.3s, opacity 0.3s;
	}

	.mh-hamburger-line:nth-of-type(3) {
		@include rotate(45deg);
		@include transitionDouble(transform, 0.3s, width, 0.3s);
		width: 77%;
		margin-left: 0;
		transform-origin: bottom right;
	}
}

.mh-hamburger-line {
	@include transitionDouble(transform, 0.3s, opacity, 0.3s);
	width: 35px;
	height: 2px;
	margin-bottom: 7px;
	background: $primary;
}

.mh-hamburger-line:nth-of-type(1) {
	width: 80%;
	margin-left: 20%;
}

.mh-hamburger-line:nth-of-type(2) {
	width: 80%;
}

.mh-hamburger-line:nth-of-type(3) {
	width: 80%;
	margin-bottom: 0;
	margin-left: 20%;
}

.mh-menu {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;

	background: $transparent-bck-2;
	z-index: $z-index-6;
	overflow-y: auto;
}

.mh-menu--active {
	//not working bcs of transitiong group
	.mh-nav-item {
		@include transitionDouble(transform, 0.3s, opacity, 0.3s);
		@include translateX(0);
		opacity: 1;
	}

	.mh-nav-item:nth-of-type(2) {
		@include delay(0.2s);
	}

	.mh-nav-item:nth-of-type(3) {
		@include delay(0.4s);
	}

	.mh-nav-item:nth-of-type(4) {
		@include delay(0.6s);
	}
}

.mh-nav-items {
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 100%;
	justify-content: center;
	padding-top: 150px;
	padding-bottom: 150px;
}

.mh-nav-item {
	transform: translateX(-20px);
	opacity: 0;
	margin-bottom: 60px;

	.nav-item__title {
		font-size: 60px;
	}
}

.mh-nav-item:last-of-type {
	margin-bottom: 0;
}

.header-lang-item {
	margin-right: 20px;
}

.header-lang-item:last-of-type {
	margin-right: 0;
}

.header-lang-item__text {
	text-transform: uppercase;
	color: $light-gray;
}

.header-lang-item--active {
	.header-lang-item__text {
		color: $primary;
	}
}

.header-lang-mobile {
	display: none;
	margin-top: 50px;
	text-align: center;
}

@media (max-width: 991px) {
	.mh-hamburger-container {
		width: 36px;
	}

	.mh-logo {
		flex: 1;
		margin-right: 35px;
		text-align: center;
	}

	.header-lang {
		display: none;
	}

	.mh-nav-item {
		.nav-item__title {
			font-size: 35px;
		}
	}

	.header-lang-mobile {
		display: block;
	}

	.header-lang-item {
		margin-right: 36px;

		.paragraph {
			font-size: 20px;
		}
	}
}

@media (max-width: 767px) {
	.mh-container {
		padding: 15px 0 15px 20px;
	}

	.mh-logo {
		margin-right: 0;
		padding: 0 20px;
	}

	.mh-logo-img {
		max-width: 100%;
	}
}
