.nin-container {
	margin-bottom: 60px;
}

.nin-inner {
	position: relative;
}

.nin-inner:after {
	position: absolute;
	bottom: -5px;
	left: 0;
	width: 100%;
	height: 1px;
	content: "";
	background: $dark-gray;
}

.nin-inner--active {
	.nin-border {
		width: 100%;
	}

	.nin-placeholder {
		@include translateY(-25px);
		font-size: 14px;
	}
}

.nin-input {
	width: 100%;
	font-size: 18px;
	border: 0;
	color: $dark-gray;
	font-family: text;
	caret-color: $primary;
	background: $transparent;
}

.nin-input:focus + .nin-border {
	width: 100%;
	transition: width 0.3s;
}

.nin-input:focus ~ .nin-placeholder {
	@include translateY(-25px);
	transition: all 0.3s;
	font-size: 14px;
}

.nin-border {
	position: absolute;
	bottom: -5px;
	left: 0;
	width: 0;
	height: 1px;
	transition: width 0.3s;
	background: $primary;
	z-index: $z-index-1;
}

.nin-placeholder {
	position: absolute;
	bottom: 0;
	left: 0;
	font-size: 18px;
	@include translateY(0);
	transition: all 0.3s;
	font-family: text;
	color: $light-gray;
	pointer-events: none;
}
