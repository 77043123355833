.rt {
	height: 100%;
	margin-right: 10%;
}

.rt-margin {
	margin-right: 0;
	margin-left: 10%;
}

.rt-number {
	display: inline-block;
	position: relative;
	width: 15%;
	vertical-align: top;
}

.rt-number:after {
	position: absolute;
	top: 100px;
	left: 50%;
	width: 2px;
	height: auto;
	content: "..................................................................................................................................................................";
	color: $dirty-white;
	opacity: 0.25;
	line-height: 4px;
	word-break: break-word;
}

.rt-number--3:after {
	display: none;
}

.rt-num-invisible {
	display: none;
}

.rt-number-dots {
	position: absolute;
	top: 50%;
	left: -530px;
	width: 500px;
	height: auto;
	color: $dirty-white;
	opacity: 0.25;
	line-height: 2px;
}

.rt-number-dots2 {
	position: absolute;
	top: 50%;
	left: 120px;
	color: $dirty-white;
	opacity: 0.25;
	line-height: 4px;
}

.rt-number__num {
	font-size: 64px;
	font-family: title;
	opacity: 0.25;
	color: $dirty-white;
	line-height: 80px;
}

.rt--animate {
	.rt-text__title {
		@include transitionDouble(transform, 0.5s, opacity, 0.5s);
		@include translateX(0);
		opacity: 1;
	}

	.rt-text__paragraph {
		@include transitionDouble(transform, 0.5s, opacity, 0.5s);
		@include translateX(0);
		transition-delay: 0.3s;
		opacity: 1;
	}
}

.rt-text {
	display: inline-block;
	width: 85%;
	margin-top: 19px;
	padding-left: 30px;
}

.rt-text__title {
	@include translateX(-25px);
	margin-bottom: 20px;
	opacity: 0;
}

.rt-text__paragraph {
	@include translateX(-25px);
	margin-bottom: 10px;
	padding-left: 2px;
	opacity: 0;
}

@media (max-width: 991px) {
	.rt {
		width: 100%;
		margin: 0 auto;
	}

	.rt-margin {
		margin-right: auto;
		margin-left: auto;
	}

	.rt-text {
		width: 80%;
		margin-top: 0;
		padding-left: 0;
	}

	.rt-number {
		position: absolute;
		top: 0;
		right: 0;
		width: auto;
		vertical-align: top;
	}

	.rt-number:after {
		display: none;
	}

	.rt-number__num {
		font-size: 200px;
		opacity: 0.1;
		line-height: 120px;
	}
}

@media (max-width: 767px) {
	.rt-text {
		width: 100%;
	}
}
