.scroller-container {
	display: inline-block;
	cursor: pointer;
	user-select: none;
}

.scroller-container:hover {
	.scroller-title {
		@include transitionE(color, 0.2s);
		color: $primary;
	}

	.scroller:before {
		@include transitionE(background-color, 0.2s);
		background-color: $primary;
	}
}

.scroller-title {
	@include transitionE(color, 0.2s);
	margin-top: 15px;
	font-size: 13px;
	text-align: center;
	font-family: text;
	color: $light-gray;
	text-transform: uppercase;
	letter-spacing: 2px;
}

.scroller {
	position: relative;
	height: 30px;
	overflow: hidden;
}

.scroller:before {
	@include transitionE(background-color, 0.2s);
	position: absolute;
	top: 0;
	left: 50%;
	width: 1px;
	height: 100%;
	background: $dirty-white;
	content: "";
	animation: 1.6s ease-in infinite scroll;
}

@keyframes scroll {
	0% {
		top: -100%;
	}

	70% {
		top: 100%;
	}

	100% {
		top: 100%;
	}
}

@media (max-width: 767px) {
	.scroller {
		height: 20px;
	}

	.scroller-title {
		font-size: 12px;
	}
}
