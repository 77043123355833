html,
body {
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
	background: $background !important;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

::selection {
	background: $primary;
	color: #000000;
}

::-moz-selection {
	background: $primary;
	color: #000000;
}

a,
a:hover,
a:focus,
a:visited {
	color: inherit;
	text-decoration: none;
}

p {
	margin-bottom: 0;
}

ul {
	margin: 0;
	padding: 0;
}

li {
	list-style: none;
	font-style: normal;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
}

input:focus {
	outline: 0;
}

.invisible {
	display: none;
}

.mega-big-title {
	font-size: 64px;
	color: $dirty-white;
	font-family: title;
}

.medium-title {
	font-size: 30px;
	color: $dark-gray;
	font-family: subtitle;
}

.medium-title-s {
	font-size: 22px;
	color: $dark-gray;
	font-family: subtitle;
}

.small-title {
	font-size: 20px;
	color: $light-gray;
	font-family: title;
}

.medium-title-n {
	font-size: 48px;
	color: $dirty-white;
	font-family: title;
}

.small-title-n {
	font-size: 24px;
	color: $dirty-white;
	font-family: title;
}

.tiny-title {
	font-size: 15px;
	color: $dirty-white;
	font-family: title;
	text-transform: uppercase;
}

.subtitle {
	font-size: 18px;
	font-family: subtitle;
	color: $light-gray;
}

.paragraph {
	font-size: 16px;
	font-family: text;
	line-height: 30px;
	color: $dirty-white;
}

.section-spacing {
	margin-top: $section-spacing;
	margin-bottom: $section-spacing;
}

.padding-spacing {
	padding-top: $section-spacing;
	padding-bottom: $section-spacing;
}

@media (max-width: 991px) {
	.section-spacing {
		margin-top: $section-spacing--sm;
		margin-bottom: $section-spacing--sm;
	}

	.padding-spacing {
		padding-top: $section-spacing--sm;
		padding-bottom: $section-spacing--sm;
	}

	.container {
		padding-right: 50px !important;
		padding-left: 50px !important;
	}

	.container-fluid {
		padding-right: 50px !important;
		padding-left: 50px !important;
	}

	.mega-big-title {
		font-size: 90px;
	}
}

@media (max-width: 767px) {
	.section-spacing {
		margin-top: $section-spacing--xs;
		margin-bottom: $section-spacing--xs;
	}

	.padding-spacing {
		padding-top: $section-spacing--xs;
		padding-bottom: $section-spacing--xs;
	}

	.container {
		padding-right: 20px !important;
		padding-left: 20px !important;
	}

	.container-fluid {
		padding-right: 20px !important;
		padding-left: 20px !important;
	}

	.mega-big-title {
		font-size: 60px;
	}
}
