.ttl {
	padding-top: $section-spacing;
	text-align: center;
	background: $background;
}

.ttl-paragraph {
	max-width: 600px;
	margin: 0 auto;
	margin-bottom: 20px;
}

.ttl-paragraph:last-of-type {
	margin-bottom: 0;
}

.ttl-button {
	margin-top: 60px;
	text-align: center;
}

@media (max-width: 991px) {
	.ttl {
		padding-top: $section-spacing--sm;
	}
}

@media (max-width: 767px) {
	.ttl {
		padding-top: $section-spacing--xs;
	}
}
