.rc {
	position: relative;
}

.rc-top {
	position: relative;
	width: 100%;
	max-height: 100vh;
	overflow: hidden;
}

.rc-top-image {
	max-height: 100vh;
}

.rc-top-scroller {
	position: absolute;
	bottom: 35px;
	right: 0;
	width: 100%;
	text-align: center;
}

.rc-top-scroller--animate {
	.rc-top-scroller-inner {
		@include transitionDouble(transform, 0.5s, opacity, 0.5s);
		@include translateY(0);
		transition-delay: 0.6s;
		opacity: 1;
	}
}

.rc-top-scroller-inner {
	@include translateY(25px);
	opacity: 0;
}

.rc-top-text--animate {
	position: absolute;
	top: 0;
	left: 0;
	height: 100vh;
	z-index: $z-index-2;

	.rc-top-text-inner__title {
		@include transitionDouble(transform, 0.5s, opacity, 0.5s);
		@include translateX(0);
		opacity: 1;
	}

	.rc-top-text-inner__paragraph {
		@include transitionDouble(transform, 0.5s, opacity, 0.5s);
		@include translateX(0);
		transition-delay: 0.3s;
		opacity: 1;
	}
}

.rc-top-text-inner {
	position: absolute;
	bottom: 50px;
	left: 50px;
	width: 500px;
}

.rc-top-text-inner__title {
	@include translateX(-25px);
	margin-bottom: 15px;
	opacity: 0;
	text-align: left !important;
}

.rc-top-text-inner__paragraph {
	@include translateX(-25px);
	opacity: 0;
}

.rc-desc {
	padding-top: 150px;
	position: relative;
}

.rc-project-title {
	color: $primary;
	text-align: center;
	margin-bottom: 25px;
}

.rc-desc-title {
	width: 100%;
	text-align: center;
}

.rc-container-fluid {
	max-width: 1905px;
}

.rc-desc-row {
	position: relative;
	margin-top: 150px;
}

.rc-img-inner {
	@include transitionCubic(transform, 0.3s);
	position: relative;
	width: 100%;
	height: 100%;
}

.rc-img {
	position: relative;
	width: 90%;
	height: 350px;
	margin-left: 10%;
	z-index: $z-index-1;
}

.rc-img--no-margin {
	margin-left: 0;
}

.rc-img:before {
	position: absolute;
	bottom: -5px;
	left: -5px;
	width: 90%;
	height: 90%;
	background: $primary;
	content: "";
}

.rc-conclusion {
	max-width: 1000px;
	margin: 0 auto;
	text-align: center;
}

.rc-youtube {
	position: relative;
	padding-bottom: 56.25%;
}

.rc-youtube iframe {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
}

.rc-poster {
	// height: 800px;
}

@media (max-width: 991px) {
	.rc {
		margin-top: 60px;
	}

	.rc-desc-row {
		margin-top: $section-spacing--sm;
	}

	.rc-img {
		width: 100%;
		height: 400px;
		margin: 0 auto;
		margin-top: 50px;
	}
}

@media (max-width: 767px) {
	.rc-top-text--animate {
		position: relative;
		height: auto;
	}

	.rc-top {
		height: auto;
	}

	.rc-top-text-inner {
		position: relative;
		bottom: 0;
		left: 0;
		width: 100%;
		padding: 68px 20px;
		padding-top: 200px;
	}

	.rc-top-scroller {
		display: none;
	}

	.rc-desc-row {
		margin-top: $section-spacing--xs;
	}

	.rc-img {
		height: 220px;
		margin-top: 23px;
	}
}
