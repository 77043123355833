@mixin grayscale($amount) {
    -webkit-filter: grayscale($amount);
    -moz-filter: grayscale($amount);
    -o-filter: grayscale($amount);
    -ms-filter: grayscale($amount);
    filter: grayscale($amount);
}

@mixin transition($property, $time) {
    -webkit-transition: $property $time linear;
    -moz-transition: $property $time linear;
    -o-transition: $property $time linear;
    transition: $property $time linear;
}

@mixin transitionE($property, $time) {
    -webkit-transition: $property $time ease-out;
    -moz-transition: $property $time ease-out;
    -o-transition: $property $time ease-out;
    transition: $property $time ease-out;
}

@mixin transitionCubic($property, $time) {
    -webkit-transition: $property $time cubic-bezier(0.25, 0.46, 0.45, 0.94);
    -moz-transition: $property $time cubic-bezier(0.25, 0.46, 0.45, 0.94);
    -o-transition: $property $time cubic-bezier(0.25, 0.46, 0.45, 0.94);
    transition: $property $time cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

@mixin transitionDouble($property1, $time1, $property2, $time2) {
    -webkit-transition: $property1 $time1 ease-out, $property2 $time2 ease-out;
    -moz-transition: $property1 $time1 ease-out, $property2 $time2 ease-out;
    -o-transition: $property1 $time1 ease-out, $property2 $time2 ease-out;
    transition: $property1 $time1 ease-out, $property2 $time2 ease-out;
}

@mixin rotate($deg) {
    -webkit-transform: rotate($deg);
    -moz-transform: rotate($deg);
    -o-transform: rotate($deg);
    transform: rotate($deg);
}

@mixin translateDouble($amount1, $amount2) {
    -webkit-transform: translate($amount1, $amount2) translate3d(0, 0, 0);
    -moz-transform: translate($amount1, $amount2);
    -o-transform: translate($amount1, $amount2);
    transform: translate($amount1, $amount2) translate3d(0, 0, 0);
}

@mixin translateX($amount) {
    -webkit-transform: translateX($amount) translate3d(0, 0, 0);
    -moz-transform: translateX($amount);
    -o-transform: translateX($amount);
    transform: translateX($amount) translate3d(0, 0, 0);
}

@mixin translateY($amount) {
    -webkit-transform: translateY($amount) translate3d(0, 0, 0);
    -moz-transform: translateY($amount);
    -o-transform: translateY($amount);
    transform: translateY($amount) translate3d(0, 0, 0);
}

@mixin centerV {
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
}

@mixin disable {
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    filter: grayscale(100%);
    pointer-events: none;
    opacity: 0.4;
}

@mixin centerH {
    position: relative;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
}

@mixin delay($amount) {
    -webkit-transition-delay: $amount;
    -moz-transition-delay: $amount;
    -ms-transition-delay: $amount;
    -o-transition-delay: $amount;
    transition-delay: $amount;
}

@mixin scale($arg1, $arg2) {
    -webkit-transform: scale($arg1, $arg2) translate3d(0, 0, 0);
    -moz-transform: scale($arg1, $arg2);
    -ms-transform: scale($arg1, $arg2);
    -o-transform: scale($arg1, $arg2);
    transform: scale($arg1, $arg2) translate3d(0, 0, 0);
}

@mixin aliasing {
    -webkit-transform: translate3d(0, 0, 0);
}

@mixin gradient($arg1, $arg2) {
    background-image: -webkit-linear-gradient(top, $arg1, $arg2);
    background-image: -moz-linear-gradient(top, $arg1, $arg2);
    background-image: -ms-linear-gradient(top, $arg1, $arg2);
    background-image: -o-linear-gradient(top, $arg1, $arg2);
    background-image: linear-gradient(top, $arg1, $arg2);
}