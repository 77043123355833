.contact-section {
	padding-top: $section-spacing;
}

.con-info-img {
	display: inline-block;
	position: relative;
	width: 45%;
	height: 500px;
}

.con-info-img:after {
	position: absolute;
	right: -15px;
	bottom: 0;
	width: 15px;
	height: 60%;
	content: "";
	background: $primary;
}

.con-info-text {
	margin-bottom: 80px;
	text-align: center;
}

.con-info-text__item {
	margin-bottom: 10px;
}

.con-follow-us {
	margin-top: 80px;
	text-align: center;
}

.con-follow-us__icons {
	display: inline-block;
}

.con-soc-item {
	display: inline-block;
	position: relative;
	margin-right: 30px;
}

.con-soc-item:hover {
	.con-soc-item__img {
		opacity: 0;
	}

	.con-soc-item__img--colored {
		opacity: 1;
	}
}

.con-soc-item__img {
	width: 50px;
}

.con-soc-item__img--colored {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	opacity: 0;
}

@media (max-width: 991px) {
	.con-info {
		height: auto;
	}

	.con-info-text {
		display: block;
		margin-left: 0;
		text-align: center;
	}
}

@media (max-width: 767px) {
	.contact-section {
		padding-top: $section-spacing--xs;
	}
}
