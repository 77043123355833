.nav-item {
	position: relative;
}

.nav-item:hover:after {
	position: absolute;
}

.nav-item__title {
	@include transitionCubic(color, 0.2s);
	display: block;
	position: relative;
	font-size: 16px;
	font-family: title;
	letter-spacing: 1px;
	color: $light-gray;
}

// .nav-item__title:after {
//     @include transition(width, 0.2s);
//     position: absolute;
//     top: 10px;
//     right: 0;
//     left: 0;
//     height: 0;
//     content: "";
//     background: $primary;
// }

.nav-item__title:hover {
	@include transitionCubic(color, 0.2s);
	color: $primary;
}

// .nav-item__title:hover:after {
//     @include transition(width, 0.2s);
//     right: -7px;
//     left: -7px;
//     height: 2px;
// }
