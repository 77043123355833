.cf-send-button {
    margin-top: 90px;
    text-align: center;
}

.cf-message {
    font-size: 15px;
    color: $dirty-white;
    font-family: text;
}

.cf-message-container {
    min-height: 20px;
    margin-top: 40px;
}