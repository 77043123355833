.anim-title {
	padding-bottom: 54px;
	text-align: center;
}

.big-title {
	font-size: 38px;
	text-align: center;
	color: $dark-gray;
	font-family: title;
}

.anim-title--animate1 {
	.title-border__text:after {
		@include transitionCubic(width, 0.3s);
		width: 100%;
	}
}

// .anim-title--animate2 {
// 	.title-border:after {
// 		@include transitionCubic(left, 0.5s);
// 		right: 50%;
// 		left: 50%;
// 	}
// }

.title-border {
	position: relative;
}

.title-border__text {
	position: relative;
	display: inline-block;
	font-size: 48px;
	letter-spacing: 1px;
	color: $dirty-white;
}

.title-border__text:after {
	position: absolute;
	bottom: -12px;
	left: 0;
	width: 0;
	height: 7px;
	content: "";
	background: $primary;
}
