.nfooter {
	margin-top: $section-spacing;
	padding: 40px 70px;
}

.nfooter-copyright {
	font-size: 13px;
	font-family: text;
	color: $dark-gray;
}

.nfooter-nav {
	text-align: center;
}

.nfooter-nav-item {
	display: inline-block;
	margin: 0 25px;
}

.nfooter-nav-item__title {
	@include transition(color, 0.2s);
	display: block;
	position: relative;
	font-size: 14px;
	font-family: title;
	color: $dark-gray;
	text-transform: uppercase;
	letter-spacing: 1px;
}

.nfooter-nav-item__title:hover {
	@include transition(color, 0.2s);
	color: $primary;
}

.nfooter-soc {
	text-align: right;
}

.nfooter-soc-item {
	@include transitionCubic(transform, 0.3s);
	@include translateY(0);
	display: inline-block;
	position: relative;
	margin-right: 30px;
}

.nfooter-soc-item:hover {
	@include transitionCubic(transform, 0.3s);
	@include translateY(-6px);

	.nfooter-soc-item__img {
		opacity: 0;
	}

	.nfooter-soc-item__img--colored {
		opacity: 1;
	}
}

.nfooter-soc-item__img {
	width: 30px;
}

.nfooter-soc-item__img--colored {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	opacity: 0;
}

@media (max-width: 991px) {
	.nfooter {
		margin-top: 70px;
		padding: 0 0 20px;
	}

	.nfooter-soc {
		text-align: center;
	}

	.nfooter-copyright {
		display: block;
		margin-top: 30px;
		text-align: center;
	}

	.nfooter-soc-item:last-of-type {
		margin-right: 0;
	}
}

@media (max-width: 767px) {
	.nfooter {
		margin-top: $section-spacing--xs;
	}
}
