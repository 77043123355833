$section-spacing: 210px;
$section-spacing--sm: 180px;
$section-spacing--xs: 120px;

// colors
$background: #131313;
$primary: #f8dc08;
$dark-gray: #7d7d7d;
$light-gray: #bbbaba;
$dirty-white: #ebebeb;
$white: #ffffff;
$primary-img-bck: rgba(198, 103, 40, 0.5);
$gray-img-bck: rgba(125, 125, 125, 0.05);
$transparent-bck: rgba(0, 0, 0, 0.3);
$transparent-bck-2: rgba(0, 0, 0, 0.9);
$transparent: rgba(0, 0, 0, 0);

// z-indexes
$z-index-1: 100;
$z-index-2: 200;
$z-index-3: 300;
$z-index-4: 400;
$z-index-5: 500;
$z-index-6: 600;
$z-index-7: 700;
$z-index-8: 800;
$z-index-9: 900;

// fonts
@font-face {
    font-family: text;
    src: url(/fonts/OpenSans-Regular.woff);
}

@font-face {
    font-family: subtitle;
    src: url(/fonts/OpenSans-SemiBold.woff);
}

@font-face {
    font-family: title;
    src: url(/fonts/OpenSans-Bold.woff);
}
