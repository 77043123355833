.rg-image {
	position: relative;
	margin-bottom: 30px;
	cursor: pointer;
	overflow: hidden;
}

.rg-image:hover {
	.rg-image-inner {
		@include transitionCubic(transform, 0.5s);
		@include scale(1.09, 1.09);
	}
}

.rg-image-inner {
	@include transitionCubic(transform, 0.5s);
	padding-top: 70%;
}

.react-images__positioner {
	transition: none !important;
}

@media (max-width: 991px) {
	.rc-modal-img {
		width: 600px;
		height: 400px;
	}
}

@media (max-width: 767px) {
	.rg-image {
		margin-bottom: 20px;
	}

	.rc-modal-img {
		width: 80%;
		height: 220px;
	}
}
