.aion-button {
	display: inline-block;
	cursor: pointer;
}

.ab__title {
	@include transitionDouble(background, 0.25s, color, 0.25s);
	display: block;
	font-size: 15px;
	text-transform: uppercase;
	font-family: title;
	color: $light-gray;
	border: 1px solid $primary;
	padding: 13px 40px 13px;
}

.ab__title:hover {
	@include transitionDouble(background, 0.25s, color, 0.25s);
	background: $primary;
	color: $background;
}
