.landing {
	position: relative;
	height: 100vh;
	min-height: 900px;
	background: $background;
}

.lan-ginger-line {
	position: absolute;
	bottom: 0;
	left: 30px;
	width: 1px;
	height: 50%;
	background: $primary;
}

.lan-ginger-line--right {
	right: 30px;
	left: initial;
	height: 85%;
}

.lan-left {
	display: inline-block;
	position: relative;
	width: 45%;
	height: 100%;
	text-align: center;
}

.lan-image-1 {
	display: inline-block;
	position: relative;
	width: 100%;
	margin-top: 15%;
}

.lan-ginger-bck {
	position: absolute;
	bottom: -30px;
	width: 180%;
	height: 80%;
	background: $primary;
	opacity: 0.99;
}

.lan-image-1__img {
	position: relative;
	margin-left: 50px;
	z-index: $z-index-1;
	background: $gray-img-bck;
}

.lan-image-2 {
	position: absolute;
	right: 0;
	bottom: 0;
	width: 45%;
}

.lan-image-2__img {
	background: $gray-img-bck;
}

.lan-text {
	position: relative;
	width: 80%;
	margin: 0 auto;
	margin-top: 50px;
	z-index: $z-index-1;
}

.lan-text__title {
	margin: 20px 0 40px;
}
