.references {
	padding-top: $section-spacing;
	overflow: hidden;
	overflow-x: hidden;
}

.references-title {
	margin-bottom: 100px;
	text-align: center;
}

.references-container {
	max-width: 1000px;
	margin: 0 auto;
}

.reference-item {
	@include translateY(50px);
	position: relative;
	width: 100%;
	height: 350px;
	margin-bottom: 30px;
	cursor: pointer;
	z-index: $z-index-1;
	opacity: 0;
}

.references-row--animate {
	.reference-item--anim-0 {
		@include translateY(0);
		@include transitionDouble(transform, 0.5s, opacity, 0.5s);
		opacity: 1;
	}

	.reference-item--anim-1 {
		@include translateY(0);
		@include transitionDouble(transform, 0.5s, opacity, 0.5s);
		transition-delay: 0.4s;
		opacity: 1;
	}

	.reference-item--anim-2 {
		@include translateY(0);
		@include transitionDouble(transform, 0.5s, opacity, 0.5s);
		transition-delay: 0.8s;
		opacity: 1;
	}

	.reference-item--anim-3 {
		@include translateY(0);
		@include transitionDouble(transform, 0.5s, opacity, 0.5s);
		transition-delay: 1.2s;
		opacity: 1;
	}
}

.alt-imageloader__background {
	@include transitionCubic(transform, 0.5s);
}

.reference-item:hover {
	.alt-imageloader__background {
		@include transitionCubic(transform, 0.5s);
		@include scale(1.09, 1.09);
	}

	.reference-item-overlay {
		@include transitionCubic(opacity, 0.4s);
		transition-delay: 0.1s;
		opacity: 1;
	}

	.reference-item-text {
		@include translateX(0);
		@include transitionCubic(transform, 0.4s);
		transition-delay: 0.1s;
	}
}

.reference-item-overlay {
	@include transitionCubic(opacity, 0.5s);
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0;
	background: rgba($color: #000000, $alpha: 0.1);
}

.reference-item-text {
	@include transitionCubic(transform, 0.4s);
	@include translateX(-30px);
	position: absolute;
	bottom: 30px;
	left: 0;
	padding: 10px 0;
	padding-left: 50px;
	transition-delay: 0.1s;
}

.reference-item-text p {
	color: $primary;
}

.reference-item-text__title {
	text-transform: uppercase;
}

.references-button {
	text-align: center;
	margin-top: 40px;
}

@media (max-width: 991px) {
	.references {
		padding-top: $section-spacing--sm;
	}

	.reference-item {
		height: 250px;
	}

	.reference-item-overlay {
		opacity: 1;
	}

	.reference-item-text {
		bottom: 5px;
	}
}

@media (max-width: 767px) {
	.references {
		padding-top: $section-spacing--xs;
	}

	.reference-item {
		height: 230px;
		margin-bottom: 20px;
	}
}
