.about-title {
	color: $primary;
	font-size: 48px;
	font-family: title;
	margin-bottom: 60px;
}

.about-paragraph {
	margin-bottom: 20px;
}

.about-paragraph:last-of-type {
	margin-bottom: 0;
}

.about-team {
	margin-top: 150px;
}

.about-team-member {
	margin-top: 80px;
}

.about-team h2,
.about-team h3 {
	color: $primary;
}

.about-team-member-image {
	display: inline-block;
	width: 50%;
	max-width: 390px;
	vertical-align: top;
}

.about-team-member-text {
	display: inline-block;
	width: 50%;
	padding-left: 100px;
	max-width: 550px;
	vertical-align: top;
}

.about-team-member-text h3 {
	margin-bottom: 20px;
}

@media (max-width: 991px) {
	.about-team-member-image {
		width: 35%;
	}

	.about-team-member-text {
		width: 65%;
		padding-left: 30px;
	}
}

@media (max-width: 767px) {
	.about-team-member-image {
		width: 100%;
		margin-bottom: 28px;
	}

	.about-team-member-text {
		width: 100%;
		padding-left: 0;
	}
}
