.input-item {
	position: relative;
	margin-bottom: 40px;
}

.input-item:after {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 1px;
	content: "";
	background: $primary;
}

.input-item__input {
	width: 100%;
	padding: 0 0 6px;
	font-size: 18px;
	background: rgba(0, 0, 0, 0);
	border: none;
	color: $dirty-white;
	font-family: text;
	caret-color: $primary;
}

.input-item__input::-moz-placeholder {
	color: $dark-gray;
	opacity: 1;
}

.input-item__input:-ms-input-placeholder {
	color: $dark-gray;
}

.input-item__input::-webkit-input-placeholder {
	color: $dark-gray;
}

.input-item__border {
	@include transition(width, 0.3s);
	@include delay(0.1s);
	position: absolute;
	top: -4px;
	left: 0;
	width: 0;
	height: 1px;
	background: $primary;
}

.input-item__border:after {
	@include transition(height, 0.1s);
	position: absolute;
	top: 0;
	right: 0;
	width: 1px;
	height: 0;
	background: $primary;
	content: "";
}

.input-item__input:focus + .input-item__border {
	@include transition(width, 0.3s);
	width: 100%;
}

.input-item__input:focus + .input-item__border:after {
	@include transition(height, 0.1s);
	@include delay(0.3s);
	height: 34px;
}

.input-item--full-width {
	max-width: 100%;
}
