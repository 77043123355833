.landing-container {
	position: relative;
	width: 100%;
	height: 100vh;
	overflow: hidden;
}

.video-container {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.video-inner {
	height: 100%;
}

.video {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.lan-scroller {
	position: absolute;
	right: 0;
	width: 100%;
	bottom: 36px;
	display: flex;
	justify-content: center;
}

@media (max-width: 991px) {
	.landing-container {
		height: auto;
		padding-bottom: 140px;
	}

	.video-container {
		position: relative;
		height: auto;
		padding-bottom: 100%;
	}

	.video-inner {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	.lan-scroller {
		bottom: 35px;
	}
}

@media (max-width: 767px) {
	.video-container {
		padding-bottom: 120%;
	}
}
