.headroom-wrapper{
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    z-index: $z-index-4;
}

.headroom {
    top: 0;
    right: 0;
    left: 0;
}

.headroom--unfixed {
    position: relative;
    @include translateY(0);
}

.headroom--scrolled {
    will-change: transform;
    @include transitionE(transform, 0.2s);
}

.headroom--unpinned {
    position: fixed;
    @include translateY(-125%);
}

.headroom--pinned {
    position: fixed;
    @include translateY(0);
}