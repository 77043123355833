.services {
	padding-top: $section-spacing;
}

.services-items {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.services-item {
	@include translateY(50px);
	margin-bottom: 70px;
	opacity: 0;
	text-align: center;
	width: 25%;
}

.row-animate {
	.services-item--anim-0 {
		@include translateY(0);
		@include transitionDouble(transform, 0.5s, opacity, 0.5s);
		opacity: 1;
	}

	.services-item--anim-1 {
		@include translateY(0);
		@include transitionDouble(transform, 0.5s, opacity, 0.5s);
		transition-delay: 0.4s;
		opacity: 1;
	}

	.services-item--anim-2 {
		@include translateY(0);
		@include transitionDouble(transform, 0.5s, opacity, 0.5s);
		transition-delay: 0.8s;
		opacity: 1;
	}

	.services-item--anim-3 {
		@include translateY(0);
		@include transitionDouble(transform, 0.5s, opacity, 0.5s);
		transition-delay: 1.2s;
		opacity: 1;
	}

	.services-item--anim-4 {
		@include translateY(0);
		@include transitionDouble(transform, 0.5s, opacity, 0.5s);
		transition-delay: 1.6s;
		opacity: 1;
	}

	.services-item--anim-5 {
		@include translateY(0);
		@include transitionDouble(transform, 0.5s, opacity, 0.5s);
		transition-delay: 2s;
		opacity: 1;
	}

	.services-item--anim-6 {
		@include translateY(0);
		@include transitionDouble(transform, 0.5s, opacity, 0.5s);
		transition-delay: 2.4s;
		opacity: 1;
	}

	.services-item--anim-7 {
		@include translateY(0);
		@include transitionDouble(transform, 0.5s, opacity, 0.5s);
		margin-bottom: 0;
		transition-delay: 2.8s;
		opacity: 1;
	}
}

.services-item-img {
	width: 78px;
	height: 80px;
}

.services-item__title {
	margin-top: 20px;
}

@media (max-width: 991px) {
	.services {
		padding-top: $section-spacing--sm;
	}

	.services-item {
		width: 33%;
	}
}

@media (max-width: 767px) {
	.services {
		padding-top: $section-spacing--xs;
	}

	.services-item {
		margin: 0 auto 50px;
	}

    .services-item {
		width: 50%;
	}
}
