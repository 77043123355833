.header {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	z-index: $z-index-2;
	text-align: center;
}

.header-logo {
	position: absolute;
	top: 19px;
	left: 50px;
	width: 140px;
}

.header-nav {
	display: inline-block;
	position: relative;
}

.header-nav-item {
	display: inline-block;
	position: relative;
	margin-right: 50px;
	padding: 30px 0 32px;
}

.header-nav-item:last-of-type {
	margin-right: 0;
}
